//
// Component: Dropdown
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@dropdown-box-shadow:                           none;

@dropdown-nav-divider-box-shadow:               none;


// Component
// ========================================================================

.hook-dropdown() when not (@dropdown-box-shadow = none) {
    box-shadow: @dropdown-box-shadow;
}


// Nav
// ========================================================================

.hook-dropdown-nav() {}

.hook-dropdown-nav-item() {}

.hook-dropdown-nav-item-hover() {}

.hook-dropdown-nav-subtitle() {}

.hook-dropdown-nav-header() {}

.hook-dropdown-nav-divider() when not (@dropdown-nav-divider-box-shadow = none) {
    box-shadow: @dropdown-nav-divider-box-shadow;
}


// Miscellaneous
// ========================================================================

.hook-dropdown-misc() {}