//
// Component: Base
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@text-meta-link-color:                          @text-meta-color;
@text-meta-link-hover-color:                    @global-color;


// Style modifiers
// ========================================================================

.hook-text-lead() {}

.hook-text-meta() {
   > a { color: @text-meta-link-color; }

   > a:hover {
        color: @text-meta-link-hover-color;
        text-decoration: none;
    }

}


// Size modifiers
// ========================================================================

.hook-text-small() {}

.hook-text-large() {}


// Background modifier
// ========================================================================

.hook-text-background() {}


// Miscellaneous
// ========================================================================

.hook-text-misc() {}


// Inverse
// ========================================================================

.hook-inverse-text-lead() {}
.hook-inverse-text-meta() {}