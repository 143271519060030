//
// Component: Base
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@base-code-border-radius:                       0;

@base-pre-border-radius:                        0;


// Body
// ========================================================================

.hook-base-body() {}


// Links
// ========================================================================

.hook-base-link() {}

.hook-base-link-hover() {}


// Text-level semantics
// ========================================================================

.hook-base-code() when not (@base-code-border-radius = 0) {
    border-radius: @base-code-border-radius;
}


// Headings
// ========================================================================

.hook-base-heading() {}

.hook-base-h1() {}

.hook-base-h2() {}

.hook-base-h3() {}

.hook-base-h4() {}

.hook-base-h5() {}

.hook-base-h6() {}


// Horizontal rules
// ========================================================================

.hook-base-hr() {}


// Blockquotes
// ========================================================================

.hook-base-blockquote() {}

.hook-base-blockquote-footer() {}


// Preformatted text
// ========================================================================

.hook-base-pre() when not (@base-pre-border-radius = 0) {
    border-radius: @base-pre-border-radius;
}


// Miscellaneous
// ========================================================================

.hook-base-misc() {}


// Inverse
// ========================================================================

.hook-inverse-base-link() {}
.hook-inverse-base-link-hover() {}

.hook-inverse-base-code() {}

.hook-inverse-base-heading() {}

.hook-inverse-base-h1() {}
.hook-inverse-base-h2() {}
.hook-inverse-base-h3() {}
.hook-inverse-base-h4() {}
.hook-inverse-base-h5() {}
.hook-inverse-base-h6() {}

.hook-inverse-base-blockquote() {}
.hook-inverse-base-blockquote-footer() {}

.hook-inverse-base-hr() {}