//
// Component: Form
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@form-border-radius:                            0;

@form-multi-line-border-radius:                 ~'';

@form-radio-border-radius:                      0;

// Component
// ========================================================================

.hook-form() when not (@form-border-radius = 0) {
    border-radius: @form-border-radius;
}

.hook-form-single-line() {}

.hook-form-multi-line() when not (@form-multi-line-border-radius = ~'') {
    border-radius: @form-multi-line-border-radius;
}

.hook-form-focus() {}

.hook-form-disabled() {}


// Style modifiers
// ========================================================================

.hook-form-danger() {}

.hook-form-success() {}

.hook-form-blank() {}

.hook-form-blank-focus() {}


// Radio and checkbox
// ========================================================================

.hook-form-radio() when not (@form-radio-border-radius = 0) {
    border-radius: @form-radio-border-radius;
}

.hook-form-radio-focus() {}

.hook-form-radio-checked() {}

.hook-form-radio-checked-focus() {}

.hook-form-radio-disabled() {}


// Legend
// ========================================================================

.hook-form-legend() {}


// Label
// ========================================================================

.hook-form-label() {}


// Layout
// ========================================================================

.hook-form-stacked-label() {}

.hook-form-horizontal-label() {}


// Icon
// ========================================================================

.hook-form-icon() {}


// Miscellaneous
// ========================================================================

.hook-form-misc() {}


// Inverse
// ========================================================================

.hook-inverse-form() {}
.hook-inverse-form-focus() {}

.hook-inverse-form-radio() {}
.hook-inverse-form-radio-focus() {}

.hook-inverse-form-radio-checked() {}
.hook-inverse-form-radio-checked-focus() {}

.hook-inverse-form-label() {}

.hook-inverse-form-icon() {}
