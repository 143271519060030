.table {
  border-radius: 25px;
  overflow: hidden;
}

.uk-table-striped tbody tr:nth-child(even) {
  //background-color: @global-muted-color;
}

.pagination-info {
  padding: 10px;
}

.active > * {
  color: @global-emphasis-color;
  font-weight: 700;
}

.not-active > * {
  color: @global-muted-color;
}

.view-button {
  border-radius: 15px;
  border-color: black;
  &.uk-button-default:hover {
    background-color: black;
    color: white;
  }
}

