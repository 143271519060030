//
// Component: Table
//
// ========================================================================


// Variables
// ========================================================================

@table-header-cell-font-size:                   @global-small-font-size;
@table-header-cell-font-weight:                 normal;
@table-header-cell-color:                       @global-muted-color;


// Component
// ========================================================================

.hook-table-header-cell() {}

.hook-table-cell() {}

.hook-table-footer() {}

.hook-table-caption() {}

.hook-table-row-active() {}


// Style modifiers
// ========================================================================

.hook-table-divider() {}

.hook-table-striped() {}

.hook-table-hover() {}


// Size modifier
// ========================================================================

.hook-table-small() {}

.hook-table-large() {}


// Miscellaneous
// ========================================================================

.hook-table-misc() {

    .uk-table tbody tr { transition: background-color 0.1s linear; }

}


// Inverse
// ========================================================================

.hook-inverse-table-header-cell() {}
.hook-inverse-table-caption() {}
.hook-inverse-table-row-active() {}
.hook-inverse-table-divider() {}
.hook-inverse-table-striped() {}
.hook-inverse-table-hover() {}