//
// Component: Table
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@table-header-cell-font-family:                         inherit;
@table-header-cell-text-transform:                      uppercase;
@table-header-cell-letter-spacing:                      inherit;
@table-header-cell-font-style:                          inherit;


// Component
// ========================================================================

.hook-table-header-cell() when not (@table-header-cell-font-family = inherit) {
    font-family: @table-header-cell-font-family;
}

.hook-table-header-cell() when not (@table-header-cell-text-transform = inherit) {
    text-transform: @table-header-cell-text-transform;
}

.hook-table-header-cell() when not (@table-header-cell-letter-spacing = inherit) {
    letter-spacing: @table-header-cell-letter-spacing;
}

.hook-table-header-cell() when not (@table-header-cell-font-style = inherit) {
    font-style: @table-header-cell-font-style;
}

.hook-table-cell() {}

.hook-table-footer() {}

.hook-table-caption() {}

.hook-table-row-active() {}


// Style modifiers
// ========================================================================

.hook-table-divider() {}

.hook-table-striped() {}

.hook-table-hover() {}


// Size modifier
// ========================================================================

.hook-table-small() {}

.hook-table-large() {}


// Miscellaneous
// ========================================================================

.hook-table-misc() {}


// Inverse
// ========================================================================

.hook-inverse-table-header-cell() {}
.hook-inverse-table-caption() {}
.hook-inverse-table-row-active() {}
.hook-inverse-table-divider() {}
.hook-inverse-table-striped() {}
.hook-inverse-table-hover() {}