.toggle {
    cursor: pointer;
    height: 1.5rem;
    padding: 0.25rem;
    width: 3rem;

    &-indicator {
        background-color: @global-muted-color;
        height: 1.5rem;
        width: 1.5rem;
    }
}