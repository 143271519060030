#manage-zones-modal {

    .uk-modal-header {
        background-color: #000;
        height: 10vh;
        
        h2 {
            color: #FFF;
        }
    }

    .uk-modal-body {
        height: 90vh;
        padding: 0;
        
        #location-zone-map {
            height: 100%;
        }

        #location-zone-map-overlay {
            background-color: rgba(0, 0, 0, 0.75);
            z-index: 99;
        }
        
        #sidebar {
            min-width: 28rem;
            max-width: 30rem;
            overflow: scroll;
            
            .zone-details {
                background-color: lighten(@global-color, 45%);
                height: 2.25rem;
                overflow: hidden;
                transition: background-color 0.25s;

                &.active-zone {
                    height: auto;
                    overflow: unset;
                }

                button#remove-zone {
                    font-size: 1rem;
    
                    &:hover {
                        background-color: @global-muted-color;
                    }
                }

                h3 {
                    padding-top: 0.2rem;
                }

                &:not(.active-zone) {
                    &:hover {
                        background-color: lighten(@global-color, 35%);
                    }

                    h3 {
                        cursor: pointer;
                    }
                }

                .add-vehicle-button {
                    color: @global-secondary-background;
                    font-size: 1rem;
                    transition: color 0.25s;

                    &:hover {
                        color: @global-primary-background;
                    }
                }

                .remove-vehicle-button {
                    background-color: @global-danger-background;
                    color: #FFF;
                    cursor: pointer;
                    transition: background-color 0.25s;

                    &:hover {
                        background-color: darken(@global-danger-background, 20%);
                    }
                }
            }

            #remove-all-zones {
                &-button {
                    color: @global-danger-background;
                    transition: background-color 0.25s;

                    &:hover {
                        background-color: @global-danger-background;
                        color: #FFF;
                    }

                    &-confirm {
                        color: #FFF;
                    }
                }

                #cancel-button:hover {
                    background-color: @global-primary-background;
                    color: #FFF;
                }
            }
        }
    }
}

.hook-modal-close-full() {
    position: static;

    &:hover {
        background-color: @global-primary-background;
        color: #FFF;
    }
}
