//
// Component: Modal
//
// ========================================================================


// Variables
// ========================================================================

@modal-header-background:                       @modal-dialog-background;
@modal-footer-background:                       @modal-dialog-background;

//
// New
//

@modal-header-border-width:                    @global-border-width;
@modal-header-border:                          @global-border;

@modal-footer-border-width:                    @global-border-width;
@modal-footer-border:                          @global-border;

@modal-close-full-padding:                     10px;
@modal-close-full-background:                  @modal-dialog-background;

@modal-close-full-padding-m:                   @global-margin;


// Component
// ========================================================================

.hook-modal() {}


// Dialog
// ========================================================================

.hook-modal-dialog() {}


// Full
// ========================================================================

.hook-modal-full() {}


// Sections
// ========================================================================

.hook-modal-header() { border-bottom: @modal-header-border-width solid @modal-header-border; }

.hook-modal-body() {}

.hook-modal-footer() { border-top: @modal-footer-border-width solid @modal-footer-border; }


// Title
// ========================================================================

.hook-modal-title() {}


// Close
// ========================================================================

.hook-modal-close() {}

.hook-modal-close-hover() {}

.hook-modal-close-default() {}

.hook-modal-close-default-hover() {}

.hook-modal-close-outside() {}

.hook-modal-close-outside-hover() {}

.hook-modal-close-full() {
    top: 0;
    right: 0;
    padding: @modal-close-full-padding;
    background: @modal-close-full-background;
}

.hook-modal-close-full-hover() {}


// Miscellaneous
// ========================================================================

.hook-modal-misc() {

    /* Tablet landscape and bigger */
    @media (min-width: @breakpoint-medium) {

        .uk-modal-close-full {
            padding: @modal-close-full-padding-m;
        }

    }

}