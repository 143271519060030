@import "Components/LowenTable.less";
@import "Components/success-button.less";
@import "Components/danger-button.less";
@import "Components/modal.less";
@import "Components/toggle.less";

.branding {
  padding: 0;
}

.cg-logo {
  display: block;
  box-sizing: content-box;
  width: 175px;
  height: auto;
}

.top-toolbar {
  .cg-logo {
    display: block;
    box-sizing: content-box;
    width: 105px;
    height: auto;
  }
}

.footer-logo {
  height: 50px;
  width: auto;
}

.error-handler-modal {
  height: 100%;
  min-height: 30px;
  padding: 0px !important;
  overflow: hidden;
  border-radius: 0 0 20px 20px;
  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out, padding 0.5s ease-in-out, background-color 0.5s ease-in-out;
  z-index: -1;
}

.show-error-handler-modal {
  padding: 5px !important;
  transform: translateY(0);
}

.error-handler-button {
  transition: background-color 0.5s ease-in-out;
}

.readonly {
  pointer-events: none;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.close-options:hover {
  background-color: rgba(255, 255, 255, .1);
}

//************** Below here are styles imported from <style scoped> tags **********//

//.aq-card-container {
//  //border-radius: 30px;
//  overflow: hidden;
//  box-sizing: border-box;
//  padding: 20px;
//}

//.aq-card {
//  /* height: 65px; */
//  overflow: hidden;
//  transition: height 0.5s ease-in-out;
//}
//
//.aq-card-left {
//  box-sizing: border-box;
//  //border-radius: 20px;
//}
.aq-object-card {
  //border-radius: 20px;
  overflow: hidden;

  p {
    line-height: 1;
    margin: 0;
  }

  span {
    line-height: 1;
    margin: 0;
    padding: 0;
  }

}

.transparent {
  background-color: transparent;
}

.index-1 {
  z-index: 0;
}

.selected {
  border: 2px solid rgb(0, 85, 161);
  border-radius: 7px;
}

.selected::after {
  top: 0 !important;
}


.uk-navbar-nav li:has(> a.uk-active) {
  font-weight: bolder;
}

.uk-navbar-dropdown-nav li a.uk-active,
.uk-dropdown-nav li a.uk-active {
  border-left: 2px solid @global-primary-background;
  font-weight: bolder;
}

.uk-container {
  /* max-width: 100%; */
  box-shadow: none;
}

.top-toolbar {
  background-color: white !important;
}

.mobile-menu-toggle {
  width: fit-content;
  height: fit-content;
  // margin: 20px;
}

.mobile-menu-toggle {
  cursor: pointer;
}

.mobile-menu-toggle:hover > *,
.mobile-menu-toggle:focus > * {
  background-color: #0071bc;
  background-position: 0% 0%;
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

.square {
  /* background-color: #0055a1; */
  height: 10px;
  width: 10px;
  margin: 2px;
  border: 3px solid #0071bc;
  background-image: linear-gradient(45deg, to bottom, #0071bc 0%, #0071bc 50%, transparent 50%, transparent 100%);
  background-size: 200% 500%;
  background-position: 0% 100%;
  transition: all 0.3s ease-in-out;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}


.cog {
  animation: spin 5s linear infinite;
  color: rgb(33, 84, 173);
  top: 100px;

  &.cog-white {
    color: @global-muted-color;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes spin {
  0% {
    background-image: gradient;
  }
  50% {
    rotate: 180deg;
  }
  100% {
    rotate: 360deg;
  }
}


.invoice-object {
  // min-height: 170px;
}

.invoice-object:hover {
  cursor: pointer;
  // outline: 3px solid rgb(23, 183, 36);
  // outline-offset: 5px;
}

.selected {
  // border: 3px solid @global-secondary;
}

@media (max-width: 768px) {
  .invoice-details {
    flex-direction: column;
  }

  .invoice-object-container {
    flex-direction: column;

  }
}

.invoice-details {

}

.lowen-table {
  border-radius: 30px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.main-content-container {
  max-height: min-content !important;
}

.aq-group {
  position: relative;
  overflow: hidden;
}

.aq-objects {
  position: relative;
  border: none;
  height: 100%;
  //max-height: 796px;
  /* padding: 5px; */
  /* margin-right: 10px; */
  /* max-height: 20%; */
}

.lk-border-customer {
  border-left: 10px solid blue;
  border-radius: 5px 0 0 5px;
}

.close-options {
  border-radius: 5px;
  border: 1.5px solid rgba(255, 255, 255, .2);
  background-color: transparent;
  padding: 5px 10px;
  right: 10px;
  top: 10px;
  transition: background-color .2s ease-in-out;
}

.close-options:hover {
  background-color: rgba(255, 255, 255, .1);
}

.loading-animation {
  min-height: 800px;
}

.view-button {
  background-color: transparent;
  border: 1px solid black;
  color: black;
  border-radius: 15px;
}

.view-button:hover,
.view-button:focus {
  background-color: black;
  color: white;
}

.table {
  border-radius: 25px;
  overflow: hidden;
}

.toggle-table {
  border-radius: 5px;
  border-color: black;
  /* background-color: black;
   */
  /* color: white; */
  padding-inline: 3px 3px;
  /* padding-block: 3px 3px; */
  height: 28px;
  margin: 0;
  line-height: 1.5;
  transition: transform 0.2s ease-in-out;
}

.show-icon {
  transform: rotate(-90deg);
}

.hide-icon {
  transform: rotate(0deg);
}

.search-container {
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.search-button {
  padding-inline: 10px 15px;
  line-height: 1;
  background-color: transparent;
}

.search-input {
  border-radius: 20px 0 0 20px;
  padding-left: 20px;
  border: none;
}

.hide {
  display: none;
}

.show {
  display: block;
}


.card-default {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, .1);
  border-top: none;
  max-height: 0;
  padding: 0;
  opacity: 0%;
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.card-open {
  // max-height: 540px !important;
  max-height: 7250px !important;
  padding: 20px !important;
  opacity: 100% !important;
}

//.card-contents {
.aq-card {
  // height: 50px; 
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}


.pagination-controls {
  ul li a {
    padding-top: 0;
  }
}

// this grid is a million times easier to work with than uk-grid
// eliminates the need for uk-width specification and padding / margin nightmare 
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

@media (max-width: 960px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 1200px) {
  .options-objects {
    padding-left: 0;
  }
}

.invoice-link:hover {
  background-color: rgba(255, 255, 255, .1);
  text-decoration: none;
}

.options-menu {
  z-index: 10;
  transform: translateY(-15px);
  height: calc(100% - 10px);
  width: calc(100% - 10px);
  border-radius: 10px 5px;
  overflow: hidden;
}

.close-options {
  border-radius: 5px;
  border: 1.5px solid rgba(255, 255, 255, .2);
  background-color: transparent;
  padding: 5px 10px;
  right: 10px;
  top: 10px;
  transition: background-color .2s ease-in-out;
}

.height-40 {
  padding-block: 0;
  margin-block: 0;
  max-height: 40px;
}

.height-40 > * {
  padding-block: 0;
  margin-block: 0;
}

.aq-object {
  min-height: 170px;
}

.mobile-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px;
}

.mobile-drop-menu {
  left: 40px;
}

.search-input {
  border-radius: 5px;
  border: 1px solid @global-primary-background;
}

.record-manager-row {
  button {
    font-size: 0.8rem;
    margin: 0.25rem auto;
    padding: 0.25rem;
    text-align: center;
    width: 100%;
  }

  & .record-actions button:hover {
    color: @global-primary-background;
  }
}

//.js-upload:hover {
//  background-color: @global-secondary-background;
//  color: @global-background;
//}
//.js-upload:focus {
//  background-color: @global-secondary-background;
//  color: @global-background;
//}
//.js-upload:active {
//  background-color: @global-secondary-background;
//  color: @global-background;
//}
//.js-upload:target {
//  background-color: @global-secondary-background;
//  color: @global-background;
//}
//.drag-drop-panel:hover {
//  background-color: @global-secondary-background;
//  color: @global-background;
//}

.create-invoice-tool {
  // position: fixed;
  // top: 0;
  // left: 0;
  // height: 100%;
  // width: 100%;
  // background-color: rgba(0, 0, 0, .3);
  // z-index: 1000;
}

.invoice-creation-tool {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // background-color: white;
  // border-radius: 10px;
  // overflow: hidden;
  // width: 1100px;
  // max-width: 80%;
  // max-height: 80%;
}

.invoice-objects {
  height: 100%;
  max-height: 40vh;
}
@media (min-width: 641px) {
  .io-key-column {
    .uk-flex;
    .uk-flex-column;
    .uk-flex-middle;
  }
}

@media (max-width: 640px) {
  .io-key-column {
    .uk-flex;
    .uk-flex-row;
  }
}

.hide-AQ {
  height: 0;
  margin: 0px;
}


.hook-dropdown() {
  background: @global-emphasis-background;
  color: @global-emphasis-color;
}

.hook-navbar-dropdown-nav-item() {
  border-left: 2px solid transparent;
  color: @global-emphasis-color;
  padding-left: 0.5rem;
}

.hook-tab-item() {
  color: @global-emphasis-color;
}

.hook-tab-right-item() {
  border-left: 0.5rem solid #000;
  color: @global-background;
  padding: 0.75rem 1rem;
}

[uk-tab].uk-tab-right {
  background-color: #000;
  border-radius: 0.5rem 0 0 0.5rem;

  & > * > a:hover {
    color: @global-background;
  }

  & > .uk-active > a {
    background-color: @global-color;
    color: @global-background;
  }
}

.uk-switcher#business-details {
  background-color: lighten(@global-color, 45%);
  border-radius: 0 0.5rem 0.5rem 0;
}

.uk-text-save {
  color: #32d296;

  &:hover {
    color: #32d296;
  }
}

// .uk-text-strike {
//   text-decoration: line-through;
// }

.uk-select:not([multiple]):not([size]) option:disabled,
option:disabled,
option[disabled] {
  color: #CCC;
}
