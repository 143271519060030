//
// Button Success
//
@button-success-background: @global-success-background;
//@button-success-color: @global-color;
@button-success-color: @global-inverse-color;
@button-success-hover-background: darken(@global-success-background, 8%);
@button-success-hover-color: lighten(@global-inverse-color, 10%);
//@button-success-active-background: darken(@button-success-hover-background, 13%);
@button-success-active-background: darken(@button-success-background, 10%);
@button-success-active-color: @global-emphasis-color;

@internal-button-success-mode: ~'';
@internal-button-success-hover-glow-filter: ~'';

// Ripple
.hook-button-success() when (@internal-button-mode = ripple) {
  background-image: radial-gradient(circle 50% 100%, @button-success-hover-background 1%, transparent 1%);
}

.hook-button-success-hover() when (@internal-button-mode = ripple) {
  background-color: @button-success-background;
}

.hook-button-success-active() when (@internal-button-mode = ripple) {
  background-image: none;
}
.uk-button-success {
  background-color: @button-success-background;
  color: @global-success-color;
  .hook-button-success();
}

/* Hover */
.uk-button-success:hover {
  background-color: @button-success-hover-background;
  color: @button-success-hover-color;
  .hook-button-success-hover();
}

/* OnClick + Active */
.uk-button-success:active,
.uk-button-success.uk-active {
  background-color: @button-success-active-background;
  color: @button-success-active-color;
  .hook-button-success-active();
}

// Glow
.hook-button-success() when (@internal-button-success-mode = glow) {

  &:not(.uk-hack) {
    position: relative;
    z-index: 0;
    background: none !important;
    box-shadow: none !important;
    border: none !important;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    // Fix Safari flickering
    will-change: filter;
  }

  &::before {
    background-image: @internal-button-success-glow-gradient;
    filter: @internal-button-success-glow-filter;
    transition: filter @button-transition-duration ease-in-out;
  }

}

.hook-button-success-hover() when (@internal-button-success-mode = glow) and not (@internal-button-success-hover-glow-filter = ~'') {

  &::before { filter: @internal-button-success-hover-glow-filter; }

}

.uk-button-success::after:extend(.uk-button-success) when (@internal-button-success-mode = glow) {}
.uk-button-success:hover::after:extend(.uk-button-success:hover) when (@internal-button-success-mode = glow) {}
.uk-button-success:active::after:extend(.uk-button-success:active) when (@internal-button-success-mode = glow) {}