//
// Component: Slidenav
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@slidenav-border-radius:                        0;


// Component
// ========================================================================

.hook-slidenav() when not (@slidenav-border-radius = 0) {
    border-radius: @slidenav-border-radius;
}

.hook-slidenav-hover() {}

.hook-slidenav-active() {}


// Icon modifier
// ========================================================================

.hook-slidenav-previous() {}

.hook-slidenav-next() {}


// Size modifier
// ========================================================================

.hook-slidenav-large() {}


// Container
// ========================================================================

.hook-slidenav-container() {}


// Miscellaneous
// ========================================================================

.hook-icon-misc() {}


// Inverse
// ========================================================================

.hook-inverse-slidenav() {}
.hook-inverse-slidenav-hover() {}
.hook-inverse-slidenav-active() {}