//
// Global Fonts
//
@internal-fonts: ~'Inter:500,600|Open+Sans:400';
@global-font-family: 'Calibri';
@global-font-size: 16px;
@global-line-height: 1.5;
@global-2xlarge-font-size: 42px;
@global-xlarge-font-size: 32px;
@global-large-font-size: 24px;
@global-medium-font-size: 20px;
@global-small-font-size: 13px;
@global-primary-font-family: 'Calibri';
@global-primary-font-weight: 500;
@global-primary-text-transform: inherit;
@global-primary-letter-spacing: inherit;
@global-primary-font-style: inherit;
@global-secondary-font-family: 'Calibri';
@global-secondary-font-weight: 600;
@global-secondary-text-transform: uppercase;
@global-secondary-letter-spacing: 1px;
@global-secondary-font-style: normal;
//
// Global Colors
//
//@global-color: #687186;
@global-color: #565E5F;
//@global-emphasis-color: #1E3876;
@global-emphasis-color: #343434;
//@global-muted-color: #96A8BB;
@global-muted-color: #ffffff;
//@global-link-color: #4FAAE7;
@global-link-color: #0071bc;
//@global-link-hover-color: darken(@global-link-color, 8%);
@global-inverse-color: #FFF;
@global-background: #FFF;
//@global-muted-background: #F6F8FA;
@global-muted-background: #1a354c;
//@global-primary-background: #4FAAE7;
@global-primary-background: #0071bc;
//@global-secondary-background: #2853B9;
@global-secondary-background: #53aa5b;
@section-secondary-background: rgba(39,44,63,0.06);
@section-secondary-color-mode: dark;
//@global-success-background: #90DFB0;
@global-emphasis-background: #bbbbbb;
@global-success-background: #dcfee0;
@global-success-color: #53aa5b;
//@global-warning-background: #FCDC57;
@global-warning-background: #ffd447;
@global-warning-color: #5a4a00;
//@global-danger-background: #F6A2B1;
@global-danger-background: #fe7070;
@global-danger-color: #321c1c;
@global-border-width: 1px;
//@global-border: #E7E8EA;
@global-border: rgba(38, 39, 64, 0.1);
@global-border-radius: 0;
@global-form-radius: 5px;
@global-small-box-shadow: 0 2px 8px rgba(39,44,63,0.06);
@global-medium-box-shadow: 0 5px 15px rgba(39,44,63,0.06);
@global-large-box-shadow: 0 14px 25px rgba(39,44,63,0.16);
@global-xlarge-box-shadow: 0 28px 50px rgba(39,44,63,0.16);
@global-margin: 20px;
@global-small-margin: 10px;
@global-medium-margin: 40px;
@global-large-margin: 70px;
@global-xlarge-margin: 140px;
@global-gutter: 30px;
@global-small-gutter: 15px;
@global-medium-gutter: 40px;
@global-large-gutter: 70px;
@global-control-height: 48px;
@global-control-small-height: 40px;
@global-control-large-height: 56px;
@global-z-index: 1000;
@base-ins-background: fade(@global-primary-background, 8%);
@base-ins-color: @global-emphasis-color;
@base-mark-background: fade(@global-primary-background, 8%);
@base-mark-color: @global-emphasis-color;
@base-h5-font-size: 17px;
@base-selection-background: #FFDFC7;
@base-selection-color: @global-emphasis-color;
@base-blockquote-font-size: 26px;
@base-h4-font-family: @global-primary-font-family;
@base-h4-font-weight: @global-primary-font-weight;
@base-h4-text-transform: @global-primary-text-transform;
@base-h4-letter-spacing: @global-primary-letter-spacing;
@base-h4-font-style: @global-primary-font-style;
@base-h5-font-family: @global-primary-font-family;
@base-h5-font-weight: @global-primary-font-weight;
@base-h5-text-transform: @global-primary-text-transform;
@base-h5-letter-spacing: @global-primary-letter-spacing;
@base-h5-font-style: @global-primary-font-style;
@base-blockquote-font-family: @global-font-family;
@base-blockquote-font-weight: 400;
@base-blockquote-text-transform: inherit;
@base-blockquote-letter-spacing: inherit;
@base-blockquote-footer-color: @global-emphasis-color;
@base-blockquote-footer-font-style: @global-secondary-font-style;
@base-blockquote-footer-letter-spacing: @global-secondary-letter-spacing;
@base-pre-border-width: 2px;
@base-pre-border-radius: 8px;
@base-pre-border: @global-border;
@base-pre-padding: 20px;
@divider-icon-color: @global-primary-background;
@divider-small-width: 50px;
@divider-small-border-width: 2px;
@divider-small-border: @global-primary-background;
@internal-divider-icon-image: "/public/theme-icons/divider-small.svg";
@list-marker-height: 1.5em;
@list-bullet-icon-color: @global-primary-background;
@internal-list-bullet-image: "/public/theme-icons/list-bullet.svg";
@description-list-term-font-size: @global-small-font-size;
@table-header-cell-font-size: @global-small-font-size;
@table-header-cell-font-weight: @global-secondary-font-weight;
@table-header-cell-color: @global-emphasis-color;
@table-row-active-background: @global-muted-background;
@table-header-cell-font-family: @global-secondary-font-family;
@table-header-cell-text-transform: @global-secondary-text-transform;
@table-header-cell-letter-spacing: @global-secondary-letter-spacing;
@table-header-cell-font-style: @global-secondary-font-style;
@inverse-table-header-cell-color: @inverse-global-emphasis-color;
@icon-link-color: @global-emphasis-color;
@icon-link-hover-color: lighten(@global-link-color, 5%);
@icon-link-active-color: darken(@global-primary-background, 5%);
@icon-button-size: 46px;
@icon-button-color: @global-emphasis-color;
@icon-button-background: darken(@global-muted-background, 3%);
@icon-button-hover-background: darken(@icon-button-background, 2%);
@icon-button-hover-color: @global-emphasis-color;
@icon-button-active-background: darken(@icon-button-hover-background, 2%);
@icon-button-active-color: @global-emphasis-color;
//@internal-icon-button-mode: glow;
@inverse-icon-button-background: @global-inverse-color;
@inverse-icon-button-color: @global-emphasis-color;
@inverse-icon-button-hover-background: darken(@global-muted-background, 3%);
@inverse-icon-button-hover-color: @global-emphasis-color;
@inverse-icon-button-active-background: darken(@inverse-icon-button-hover-background, 2%);
@inverse-icon-button-active-color: @global-emphasis-color;
@form-range-thumb-height: 13px;
@form-range-thumb-background: @global-primary-background;
@form-padding-horizontal: 15px;
@form-background: @global-background;
@form-focus-background: lighten(@global-muted-background, 1%);
@form-small-padding-horizontal: 12px;
@form-large-padding-horizontal: 18px;
@form-label-font-size: @global-small-font-size;
@form-border-width: 2px;
@form-border: @global-border;
@form-focus-border: darken(saturate(@form-border, 5%), 4%);
@form-danger-border: @global-danger-background;
@form-success-border: darken(@global-success-background, 7%);
@form-blank-focus-border: @form-border;
@form-border-radius: @global-form-radius;
@form-multi-line-border-radius: 8px;
@form-danger-focus-background: fade(@global-danger-background, 5%);
@form-success-focus-background: fade(@global-success-background, 8%);
@inverse-form-background: transparent;
@inverse-form-color: @inverse-global-color;
@inverse-form-focus-background: darken(@inverse-global-muted-background, 10%);
@inverse-form-icon-color: @inverse-global-color;
@inverse-form-icon-hover-color: @inverse-global-emphasis-color;
@inverse-form-border: @inverse-global-border;
@inverse-form-focus-border: darken(@inverse-global-color, 15%);
@button-transition-duration: 0.2s;
@button-font-size: 12px;
@button-small-font-size: 11px;
@button-large-font-size: @global-small-font-size;
@button-small-padding-horizontal: 25px;
@button-large-padding-horizontal: 35px;
@button-default-background: darken(@global-muted-background, 3%);
@button-default-hover-background: darken(@button-default-background, 2%);
@button-default-active-background: darken(@button-default-hover-background, 2%);
@button-primary-hover-background: @global-secondary-background;
@button-primary-active-background: darken(@button-primary-hover-background, 4%);
@button-secondary-hover-background: @button-primary-background;
@button-secondary-active-background: darken(@button-secondary-hover-background, 4%);
@button-danger-hover-background: darken(@button-danger-background, 4%);
@button-danger-active-background: darken(@button-danger-hover-background, 4%);
@button-text-mode: line;
@button-text-border: fade(@global-primary-background, 80%);
@button-text-border-width: 4px;
@internal-button-text-line-bottom: 3px;
@button-border-radius: @global-form-radius;
@internal-button-mode: glow;
@inverse-button-default-color: @global-emphasis-color;
@inverse-button-default-hover-color: @global-emphasis-color;
@inverse-button-default-active-color: @global-emphasis-color;
@inverse-button-primary-background: darken(@global-primary-background, 4%);
@inverse-button-primary-color: @inverse-global-emphasis-color;
@inverse-button-primary-hover-background: darken(@global-secondary-background, 4%);
@inverse-button-primary-hover-color: @inverse-global-emphasis-color;
@inverse-button-primary-active-color: @inverse-global-emphasis-color;
@inverse-button-secondary-background: darken(@global-secondary-background, 4%);
@inverse-button-secondary-color: @inverse-global-emphasis-color;
@inverse-button-secondary-hover-background: darken(@global-primary-background, 4%);
@inverse-button-secondary-hover-color: @inverse-global-emphasis-color;
@inverse-button-secondary-active-color: @inverse-global-emphasis-color;
@inverse-button-text-color: @inverse-global-emphasis-color;
@inverse-button-text-hover-color: @inverse-global-emphasis-color;
@inverse-button-text-hover-border: @inverse-global-color;
@progress-background: @button-danger-background;
@progress-bar-background: @button-success-background;
@container-small-max-width: 960px;
@container-large-max-width: 1360px;
@tile-primary-hover-background: @tile-secondary-background;
@tile-secondary-hover-background: @tile-primary-background;
//@internal-tile-hover-mode: glow;
@card-default-hover-background: darken(@card-default-background, 2%);
@card-primary-hover-background: @global-secondary-background;
@card-secondary-hover-background: @global-primary-background;
@card-badge-font-size: 11px;
@card-badge-border-radius: @global-form-radius;
//@internal-card-hover-mode: glow;
@close-color: @global-emphasis-color;
@close-hover-color: @global-primary-background;
@marker-background: @global-primary-background;
@marker-hover-background: @global-secondary-background;
@inverse-marker-color: @global-primary-background;
@inverse-marker-hover-color: @global-inverse-color;
@inverse-marker-hover-background: @global-primary-background;
@totop-padding: 16px;
@totop-color: @global-emphasis-color;
@totop-hover-color: @global-emphasis-color;
@totop-active-color: @global-emphasis-color;
@totop-background: @global-muted-background;
@totop-hover-background: darken(@totop-background, 5%);
@totop-active-background: darken(@totop-hover-background, 5%);
@totop-border-radius: @global-form-radius;
@internal-totop-mode: animate;
@alert-primary-background: @global-primary-background;
@alert-primary-color: @global-inverse-color;
@alert-success-background: @global-success-background;
@alert-success-color: @global-inverse-color;
@alert-warning-background: @global-warning-background;
@alert-warning-color: @global-inverse-color;
@alert-danger-background: @global-danger-background;
@alert-danger-color: @global-inverse-color;
@alert-close-opacity: 1;
@card-badge-height: 26px;
@card-badge-padding-horizontal: 12px;
@label-padding-vertical: 4px;
@label-padding-horizontal: 10px;
@label-font-size: 10px;
@label-border-radius: @global-form-radius;
@comment-title-font-size: @global-font-size;
@comment-meta-font-family: inherit;
@comment-meta-font-weight: inherit;
@comment-meta-text-transform: inherit;
@comment-meta-letter-spacing: inherit;
@comment-meta-font-style: inherit;
@search-icon-color: @global-emphasis-color;
@search-default-padding-horizontal: 15px;
@search-default-background: @global-background;
@search-default-focus-background: lighten(@global-muted-background, 1%);
@search-toggle-color: @global-emphasis-color;
@search-toggle-hover-color: @global-primary-background;
@search-default-border-width: 2px;
@search-default-border: @global-border;
@search-default-focus-border: darken(saturate(@form-border, 5%), 4%);
@search-default-border-radius: @global-form-radius;
@inverse-search-placeholder-color: @inverse-global-muted-color;
@inverse-search-icon-color: @inverse-global-color;
@inverse-search-default-background: transparent;
@inverse-search-default-focus-background: darken(@inverse-global-muted-background, 10%);
@inverse-search-default-border: @inverse-global-border;
@inverse-search-default-focus-border: darken(@inverse-global-color, 15%);
@accordion-item-margin-top: 30px;
@accordion-item-padding-top: @accordion-item-margin-top;
@accordion-icon-color: @global-primary-background;
@internal-accordion-icon-close-image: "/public/theme-icons/accordion-close.svg";
@internal-accordion-icon-open-image: "/public/theme-icons/accordion-open.svg";
@accordion-item-border: @global-border;
@accordion-item-border-width: @global-border-width;
@dropdown-padding: 20px;
@dropdown-background: @global-background;
@dropdown-nav-item-color: @global-muted-color;
@dropdown-nav-item-hover-color: @global-primary-background;
@dropdown-nav-sublist-item-hover-color: @global-primary-background;
@dropdown-box-shadow: @global-medium-box-shadow;
@dropdown-nav-font-family: @global-font-family;
@dropdown-nav-font-weight: 400;
@dropdown-nav-text-transform: none;
@dropdown-nav-letter-spacing: 0;
@dropdown-nav-font-style: normal;
@dropbar-padding-top: 20px;
@dropbar-background: @global-secondary-background;
@dropbar-color-mode: light;
@dropbar-top-box-shadow: 0 5px 15px -10px rgba(39,44,63,0.06);
@dropbar-bottom-box-shadow: 0 -5px 15px -10px rgba(39,44,63,0.06);
@dropbar-left-box-shadow: 5px 0 15px -10px rgba(39,44,63,0.06);
@dropbar-right-box-shadow: -5px 0 15px -10px rgba(39,44,63,0.06);
@offcanvas-overlay-background: rgba(39,44,63,0.15);
@leader-color: @global-primary-background;
@notification-message-success-background: darken(@global-success-background, 15%);
@notification-message-warning-background: darken(@global-warning-background, 15%);
@tooltip-background: @global-secondary-background;
@countdown-number-font-size-s: 3rem; // 48px
@countdown-number-font-size-m: 5rem; // 80px
@countdown-label-font-size: @global-small-font-size;
@countdown-label-color: @global-primary-background;
@countdown-label-font-family: @global-secondary-font-family;
@countdown-label-font-weight: @global-secondary-font-weight;
@countdown-label-text-transform: @global-secondary-text-transform;
@countdown-label-letter-spacing: @global-secondary-letter-spacing;
@countdown-label-font-style: @global-secondary-font-style;
@nav-default-item-color: @global-color;
@nav-default-item-hover-color: @global-primary-background;
@nav-default-item-active-color: @nav-default-item-hover-color;
@nav-default-sublist-item-hover-color: @global-primary-background;
@nav-default-sublist-item-active-color: @nav-default-sublist-item-hover-color;
@nav-primary-item-color: @global-emphasis-color;
@nav-primary-item-hover-color: @global-primary-background;
@nav-primary-item-active-color: @nav-primary-item-hover-color;
@nav-primary-subtitle-font-size: 14px;
@nav-primary-sublist-item-color: @global-muted-color;
@nav-primary-sublist-item-hover-color: @global-primary-background;
@nav-primary-sublist-item-active-color: @nav-primary-sublist-item-hover-color;
@nav-secondary-font-size: 17px;
@nav-secondary-item-hover-color: @global-primary-background;
@nav-secondary-item-active-color: @global-primary-background;
@nav-secondary-subtitle-font-size: @global-font-size;
@nav-secondary-subtitle-hover-color: @global-primary-background;
@nav-secondary-subtitle-active-color: @global-primary-background;
@nav-secondary-sublist-font-size: @global-font-size;
@nav-secondary-sublist-item-hover-color: @global-primary-background;
@nav-secondary-sublist-item-active-color: @global-primary-background;
@nav-dividers-margin-top: 10px;
@nav-header-font-weight: @global-secondary-font-weight;
@nav-default-font-family: @global-font-family;
@nav-default-font-weight: inherit;
@nav-default-text-transform: inherit;
@nav-default-letter-spacing: inherit;
@nav-default-font-style: inherit;
@nav-primary-font-family: @global-font-family;
@nav-primary-font-weight: inherit;
@nav-primary-text-transform: inherit;
@nav-primary-letter-spacing: inherit;
@nav-primary-font-style: inherit;
@nav-secondary-margin-top: 18px;
@nav-secondary-font-family: @global-primary-font-family;
@nav-secondary-font-weight: @global-primary-font-weight;
@nav-secondary-text-transform: @global-primary-text-transform;
@nav-secondary-letter-spacing: @global-primary-letter-spacing;
@nav-secondary-font-style: @global-primary-font-style;
@nav-secondary-subtitle-font-family: @global-font-family;
@nav-secondary-subtitle-font-weight: normal;
@inverse-nav-default-item-hover-color: @inverse-global-emphasis-color;
@inverse-nav-default-sublist-item-hover-color: @inverse-global-emphasis-color;
@inverse-nav-secondary-subtitle-hover-color: @inverse-global-emphasis-color;
@navbar-background: @global-background;
@navbar-nav-item-padding-horizontal: 0;
@navbar-nav-item-color: @global-emphasis-color;
@navbar-nav-item-hover-color: @global-primary-background;
@navbar-nav-item-onclick-color: darken(@global-primary-background, 8%);
@navbar-nav-item-active-color: @global-primary-background;
@navbar-toggle-color: @global-emphasis-color;
@navbar-toggle-hover-color: @global-primary-background;
@navbar-subtitle-font-size: 12px;
@navbar-dropdown-shift-margin: -@navbar-dropdown-padding;
@navbar-dropdown-width: 260px;
@navbar-dropdown-padding: 20px;
@navbar-dropdown-background: @global-secondary-background;
@navbar-dropdown-color: fade(@global-inverse-color, 80%);
@navbar-dropdown-color-mode: light;
@navbar-dropdown-large-shift-margin: -@navbar-dropdown-large-padding;
@navbar-dropdown-dropbar-padding-top: 40px;
@navbar-dropdown-nav-item-color: fade(@global-inverse-color, 60%);
@navbar-dropdown-nav-header-color: @global-inverse-color;
@navbar-dropdown-nav-item-hover-color: @global-inverse-color;
@navbar-dropdown-nav-item-active-color: @global-inverse-color;
@navbar-dropdown-nav-divider-border: @inverse-global-border;
@navbar-dropdown-nav-sublist-item-color: fade(@global-inverse-color, 60%);
@navbar-dropdown-nav-sublist-item-hover-color: @global-inverse-color;
@navbar-dropdown-nav-sublist-item-active-color: @global-inverse-color;
@navbar-border-width: @global-border-width;
@navbar-border: @global-border;
@navbar-box-shadow: @global-medium-box-shadow;
@navbar-gap-m: 40px;
@navbar-nav-gap-m: 40px;
@navbar-nav-item-font-weight: 500;
@navbar-nav-item-text-transform: inherit;
@navbar-nav-item-letter-spacing: inherit;
@navbar-nav-item-font-style: inherit;
@navbar-nav-item-line-transition-duration: 0.3s;
@navbar-nav-item-line-mode: true;
@navbar-nav-item-line-position-mode: top;
@navbar-nav-item-line-slide-mode: center;
@navbar-nav-item-line-margin-horizontal: 0;
@navbar-nav-item-line-height: 2px;
@navbar-nav-item-line-hover-height: @navbar-nav-item-line-height;
@navbar-nav-item-line-onclick-height: @navbar-nav-item-line-height;
@navbar-nav-item-line-active-height: @navbar-nav-item-line-height;
@navbar-primary-gap: 40px;
@navbar-primary-nav-gap: 40px;
@navbar-primary-gap-m: 50px;
@navbar-primary-nav-gap-m: 50px;
@navbar-primary-nav-item-font-size: @global-medium-font-size;
@navbar-dropdown-nav-item-padding-vertical: 4px;
@navbar-dropdown-box-shadow: @global-medium-box-shadow;
@navbar-dropdown-nav-font-size: 15px;
@navbar-dropdown-nav-font-family: inherit;
@navbar-dropdown-nav-font-weight: inherit;
@navbar-dropdown-nav-text-transform: inherit;
@navbar-dropdown-nav-letter-spacing: inherit;
@navbar-dropdown-nav-font-style: inherit;
.hook-navbar() when not (@navbar-box-shadow = none) and not (@navbar-border-width = 0) {
    .tm-header .tm-headerbar-top + * & { border-top: @navbar-border-width solid @navbar-border; }
    .tm-header .tm-headerbar-top + * .uk-navbar-sticky > * > & { border-top-color: transparent !important; }
    .tm-header .tm-headerbar-top + * & > * { margin-top: -1 * @navbar-border-width; }
}
@inverse-navbar-nav-item-hover-color: @inverse-global-primary-background;
@subnav-item-color: @global-color;
@subnav-item-hover-color: @global-primary-background;
@subnav-item-active-color: @global-primary-background;
@subnav-divider-border-height: 1em;
@subnav-pill-item-padding-horizontal: 14px;
@subnav-item-disabled-color: @global-muted-color;
@subnav-divider-border: @subnav-item-color;
@subnav-pill-item-border-radius: @global-form-radius;
@subnav-item-font-family: @global-font-family;
@subnav-item-font-weight: inherit;
@subnav-item-text-transform: inherit;
@subnav-item-letter-spacing: inherit;
@subnav-item-font-style: inherit;
@breadcrumb-item-hover-color: @global-primary-background;
@breadcrumb-item-active-color: @global-primary-background;
@breadcrumb-divider: "·";
@breadcrumb-divider-margin-horizontal: 10px;
@breadcrumb-item-font-family: inherit;
@breadcrumb-item-font-weight: inherit;
@breadcrumb-item-text-transform: inherit;
@breadcrumb-item-letter-spacing: inherit;
@breadcrumb-item-font-style: inherit;
@pagination-item-padding-horizontal: 13px;
@pagination-item-padding-vertical: 7px;
@pagination-item-color: @global-muted-color;
@pagination-item-hover-color: @global-primary-background;
@pagination-item-active-color: @global-inverse-color;
@pagination-item-active-background: @global-primary-background;
@pagination-item-border-radius: @global-form-radius;
@pagination-item-font-size: @global-small-font-size;
@pagination-item-next-previous-color: @global-primary-background;
@pagination-item-next-previous-hover-color: @global-primary-background;
@internal-pagination-item-next-previous-mode: animate;
@inverse-pagination-item-next-previous-color: @inverse-global-emphasis-color;
@inverse-pagination-item-next-previous-hover-color: @inverse-global-emphasis-color;
@tab-margin-horizontal: 30px;
@tab-item-padding-horizontal: 0;
@tab-vertical-item-padding-horizontal: 10px;
@tab-border-width: 0;
@tab-border: transparent;
@tab-item-border-width: 2px;
@tab-item-hover-border: @global-primary-background;
@tab-item-font-size: @global-small-font-size;
@slidenav-padding-vertical: 17px;
@slidenav-padding-horizontal: 14px;
@slidenav-color: @global-emphasis-color;
@slidenav-hover-color: @global-emphasis-color;
@slidenav-active-color: @global-emphasis-color;
@slidenav-large-padding-vertical: 27px;
@slidenav-large-padding-horizontal: 24px;
@slidenav-background: @global-muted-background;
@slidenav-hover-background: darken(@slidenav-background, 2%);
@slidenav-active-background: darken(@slidenav-hover-background, 2%);
@slidenav-border-radius: @global-form-radius;
@internal-slidenav-next-previous-mode: animate;
@inverse-slidenav-color: @global-emphasis-color;
@inverse-slidenav-hover-color: @global-emphasis-color;
@inverse-slidenav-active-color: @global-emphasis-color;
@inverse-slidenav-background: @inverse-global-primary-background;
@inverse-slidenav-hover-background: darken(@inverse-slidenav-background, 8%);
@inverse-slidenav-active-background: darken(@inverse-slidenav-hover-background, 8%);
@dotnav-item-background: darken(@global-muted-background, 8%);
@dotnav-item-hover-background: @global-primary-background;
@dotnav-item-active-background: @global-primary-background;
@inverse-dotnav-item-hover-background: @global-primary-background;
@inverse-dotnav-item-onclick-background: @global-primary-background;
@inverse-dotnav-item-active-background: @global-primary-background;
@thumbnav-item-background: transparent;
@thumbnav-item-border-width: 5px;
@thumbnav-item-hover-border: @global-danger-background;
@thumbnav-item-active-border: @thumbnav-item-hover-border;
@inverse-thumbnav-item-background: transparent;
@inverse-thumbnav-item-hover-border: @global-inverse-color;
@inverse-thumbnav-item-active-border: @global-inverse-color;
@iconnav-item-color: @global-emphasis-color;
@iconnav-item-hover-color: @global-primary-background;
@iconnav-item-active-color: darken(@global-primary-background, 5%);
@inverse-iconnav-item-color: @inverse-global-emphasis-color;
@lightbox-background: @global-secondary-background;
@lightbox-item-color: @global-inverse-color;
@lightbox-toolbar-background: fade(@lightbox-background, 20%);
@lightbox-toolbar-color: @global-inverse-color;
@lightbox-button-background: @global-inverse-color;
@lightbox-button-color: @global-emphasis-color;
@lightbox-button-hover-color: @global-emphasis-color;
@text-lead-font-size: 22px;
@text-large-font-size: @global-medium-font-size;
@text-warning-color: desaturate(darken(@global-warning-background, 10%), 8%);
@text-meta-font-family: @global-font-family;
@text-meta-font-weight: inherit;
@text-meta-text-transform: inherit;
@text-meta-letter-spacing: inherit;
@text-meta-font-style: inherit;
@logo-font-family: @global-primary-font-family;
@dropcap-color: @global-emphasis-color;
@logo-font-weight: @global-primary-font-weight;
@logo-text-transform: @global-primary-text-transform;
@logo-letter-spacing: @global-primary-letter-spacing;
@inverse-global-color: fade(@global-inverse-color, 80%);
@inverse-global-muted-color: fade(@global-inverse-color, 60%);
@inverse-global-inverse-color: @global-emphasis-color;
@inverse-global-border: fade(@global-inverse-color, 15%);