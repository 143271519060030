//
// Component: Placeholder
//
// ========================================================================


// Variables
// ========================================================================

@placeholder-background:                        transparent;

//
// New
//

@placeholder-border-width:                      @global-border-width;
@placeholder-border:                            @global-border;
@placeholder-border-style:                      dashed;


// Component
// ========================================================================

.hook-placeholder() { border: @placeholder-border-width @placeholder-border-style @placeholder-border; }


// Miscellaneous
// ========================================================================

.hook-placeholder-misc() {}