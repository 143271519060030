//
// Component: Pagination
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@pagination-item-border-radius:                 0;


// Component
// ========================================================================

.hook-pagination() {}


// Items
// ========================================================================

.hook-pagination-item() when not (@pagination-item-border-radius = 0) {
    border-radius: @pagination-item-border-radius;
}

.hook-pagination-item-hover() {}

.hook-pagination-item-active() {}

.hook-pagination-item-disabled() {}


// Miscellaneous
// ========================================================================

.hook-pagination-misc() {}


// Inverse
// ========================================================================

.hook-inverse-pagination-item() {}
.hook-inverse-pagination-item-hover() {}
.hook-inverse-pagination-item-active() {}
.hook-inverse-pagination-item-disabled() {}