//
// Core
//

@import "../node_modules/uikit/src/less/components/_import.less";
//
// Master Theme
//

@import "master/_import.less";

//
// Agile Theme
//
@import "agile/agile-variables.less";

//
// Theme
//

@theme-box-decoration-default-background: #EEECDC;
@theme-box-decoration-secondary-background: @global-secondary-background;

// Thumbnav
//

@thumbnav-item-hover-border: @global-primary-background;

//
// Installation Theme
//
@import "installation-theme.less";

// Variables
// ========================================================================
// Overrides

//
// Page
//

@theme-page-border-mode: -bottom;
@theme-page-border-width: 5px;
@theme-page-border: @global-primary-background;

//
// Page Container
//

@theme-page-container-width: 1840px;

//
// Toolbar
//

@theme-toolbar-background: @global-muted-background;
@theme-toolbar-color-mode: none;

//
// Box Decoration
//

@theme-box-decoration-top: -40px;
@theme-box-decoration-bottom: -40px;
@theme-box-decoration-left: -40px;
@theme-box-decoration-right: -40px;
@theme-box-decoration-z-index: 0;

@theme-box-decoration-default-transform-horizontal: 0;
@theme-box-decoration-default-transform-vertical: 0;
@theme-box-decoration-default-background: @global-warning-background;

@theme-box-decoration-primary-transform-horizontal: 0;
@theme-box-decoration-primary-transform-vertical: 0;
@theme-box-decoration-primary-background: @global-primary-background;

@theme-box-decoration-secondary-transform-horizontal: 0;
@theme-box-decoration-secondary-transform-vertical: 0;
@theme-box-decoration-secondary-background: @global-danger-background;

.hook-box-decoration() {

  &::after {
    content: '';
    position: absolute;
    z-index: @theme-box-decoration-z-index;
    pointer-events: none;
  }

}

.hook-box-decoration-default() {

  &::before {
    width: 120px;
    height: 60px;
    border-radius: 0 0 @global-form-radius @global-form-radius;
  }

  &::after {
    right: -20px;
    bottom: -20px;
    width: 60px;
    height: 120px;
    border-radius: 0 @global-form-radius @global-form-radius 0;
    background: @theme-box-decoration-secondary-background;
  }

}

.hook-box-decoration-primary() {

  &::before {
    left: ~'calc(100% - 40px)';
    width: 80px;
    height: 80px;
    border-radius: @global-form-radius;
  }

  &::after {
    bottom: -20px;
    left: -20px;
    width: 60px;
    height: 60px;
    border-radius: @global-form-radius 0 0 0;
    background: @theme-box-decoration-default-background;
  }

}

.hook-box-decoration-secondary() {

  &::before {
    width: 80px;
    height: 80px;
    border-radius: @global-form-radius 0 0 0;
  }

  &::after {
    right: -40px;
    bottom: -40px;
    width: 120px;
    height: 60px;
    border-radius: 0 0 @global-form-radius @global-form-radius;
    background: @theme-box-decoration-primary-background;
  }

}

//
// Lowen Ripple
//

.hook-button() when (@internal-button-mode = ripple) {

  &:not(.uk-button-text):not(.uk-button-link) {
    background-position: 50% 100%;
    background-size: 0;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(.165, .85, .45, 1);
  }

}

.hook-button-hover() when (@internal-button-mode = ripple) {

  &:not(.uk-button-text):not(.uk-button-link) {
    background-size: 15000%;
  }

}

.hook-button-active() when (@internal-button-mode = ripple) {

  &:not(.uk-button-text):not(.uk-button-link) {
    transition-duration: 0s;
  }

}

// Danger
.hook-button-danger() when (@internal-button-mode = ripple) {
  background-image: radial-gradient(circle at 50% 100%, @button-danger-hover-background 1%, transparent 1%);
}

.hook-button-danger-hover() when (@internal-button-mode = ripple) {
  background-color: @button-danger-background;
}

.hook-button-danger-active() when (@internal-button-mode = ripple) {
  background-image: none;
}

.hook-button-danger() when (@internal-button-mode = glow) {
  color: @global-danger-color;
}

.uk-button-warning {
  background-color: @global-warning-background;
  color: @global-warning-color;
}

.uk-button-warning:hover {
  background-color: darken(@global-warning-background, 8%);
  // color: @global-warning-hover-color;
}

/* Tablet Landscape and bigger */
@media (min-width: @breakpoint-medium) {

  .tm-box-decoration-default {

    &::before {
      width: 160px;
      height: 80px;
    }

    &::after {
      right: -40px;
      bottom: -40px;
      width: 80px;
      height: 160px;
    }
  }

  .tm-box-decoration-primary {

    &::before {
      left: ~'calc(100% - 60px)';
      width: 120px;
      height: 120px;
    }

    &::after {
      bottom: -30px;
      left: -40px;
      width: 100px;
      height: 100px;
    }

  }

  .tm-box-decoration-secondary {

    &::before {
      width: 100px;
      height: 100px;
    }

    &::after {
      right: -40px;
      bottom: -40px;
      width: 160px;
      height: 80px;
    }
  }

}


//
// Section
//

@section-title-color: @global-emphasis-color;
@section-title-font-size: 13px;

@internal-section-title-dash-border-height: 50px;
@internal-section-title-dash-border-width: 2px;
@internal-section-title-dash-color: @global-primary-background;


//
// Prevent scrollbar from shifting page
//

html {
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 13px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #555;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: @global-primary-background;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {

  background: #0055a1;
}

//
// Scrollbar shift correction
//

@media (min-width: 700px) {
  body {
    // padding-left: 13px;
  }
}


//
// Uk overrides
//

.uk-button-muted {
  background-color: @global-muted-background;
  color: @global-muted-color;
}

.uk-button-muted:hover {
  background-color: darken(@global-muted-background, 8%);
  // color: @global-muted-hover-color;
}

.uk-button-default:hover {
  background-color: darken(@global-emphasis-background, 8%);
  // color: @global-primary-hover-color;
}

.uk-input {
  color: @global-color;
}

.uk-input::placeholder {
  color: @global-color;
}

.uk-input:focus,
.uk-input:active {
  background-color: white;
}

.search-input {
  // background-color: transparent;
  border-color: @global-primary-background;
}

.search-input::placeholder {
  color: @global-color;
}

.search-input:active,
.search-input:focus {
  background-color: white;
  border: 2px solid @global-secondary-background !important;
}

.uk-background-default {
  background-color: lighten(@global-emphasis-background, 16%);
  color: @global-emphasis-color !important;
}

.uk-border-success {
  border-left: 10px solid @global-secondary-background;
  border-radius: 5px 0 0 5px;
}

.uk-border-danger {
  border-left: 10px solid @global-danger-background;
  border-radius: 5px 0 0 5px;
}

.uk-border-warning {
  border-left: 10px solid @global-warning-background;
  border-radius: 5px 0 0 5px;
}

.uk-input:disabled {
  background-color: @global-emphasis-background;
}

.uk-textarea:focus,
.uk-textarea:active {
  background-color: white;
}

.uk-select:focus,
.uk-select:active {
  background-color: white;
}


h4 {
  user-select: none;
}

.z-top {
  z-index: 1000;
}

//
// custom button
//

.project-button-small {
  position: realtive;
  overflow: hidden;
  user-select: none;
}

.project-button-small:hover::after {
  top: 0;
}

.project-button-small:focus,
.project-button-small:active {
  background-color: @global-secondary-background !important;
  color: @global-background;
}

.project-button-small::after {
  content: '';
  position: absolute;
  top: -35px;
  border-radius: 0 0 5000% 10%;
  left: 0%;
  width: 130%;
  height: 30px;
  background: rgba(255, 255, 255, 0.2);
  z-index: 0;
  transition: top 0.4s ease-in-out;
  pointer-events: none;
}

.project-button-large {
  position: realtive;
  overflow: hidden;
  z-index: -1;
  user-select: none;
}

.project-button-large:hover::after {
  top: 0;
}

.project-button-large::after {
  content: '';
  position: absolute;
  top: -45px;
  border-radius: 0 0 3000% 10%;
  left: -5%;
  width: 110%;
  height: 43px;
  background: rgba(255, 255, 255, 0.2);
  z-index: 0;
  transition: top 0.4s ease-in-out;
}

.selected .project-button-large::after {
  top: 0 !important;
}

//.js-upload:hover > div > button {
//  background-color: @global-secondary-background;
//  color: @global-background;
//}

//.js-upload:hover > div > button::after {
//  top: 0;
//}


//
// Search Result highlighting 
.search-result {
  background-color: rgba(black, .3) !important;
  border-radius: 5px;
  padding: 3px 10px;
  // text-align: center;
}

.aq-toggle {
  font-size: 1rem;
  font-weight: 400;
}

.aq-info {
  border-radius: 5px;
}

.pagination-controls {
  border-radius: 5px;
}

.project-list-header {
  background-color: black;
  border-radius: 5px;
}

.uk-lightbox {
  background-color: rgba(0, 0, 0, .9);
}

.uk-lightbox-toolbar {
  background-color: rgba(@global-primary-background, .8);
}

.uk-lightbox-button {
  background-color: rgba(0, 0, 0, .8);
}

.uk-lightbox-button:hover {
  background-color: rgba(@global-primary-background, .8);
}

/*.object-group-section {
  padding-left: 7px;
  padding-right: 7px;
}

.object-group-section:hover {
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
}*/


.invoice-object {
  box-sizing: border-box;
  height: 100%;
  outline-color: transparent;
  transition: background-color .3s ease-in-out, outline-color .3s ease-in-out;
}

.invoice-object-no-hover {
  box-sizing: border-box;
  height: 100%;
}

.invoice-object:hover {
  background-color: lighten(@global-secondary-background, 40%);
  outline: 3px solid @global-secondary-background;
  outline-offset: 3px;
}

.invoice-object-selected {
  outline: 3px solid lighten(@global-secondary-background, 16%);
  background-color: white;
}

.invoice-object-selected:hover {
  background-color: lighten(red, 45%);
  outline: 3px solid red;
}

.invoice-object-default:hover {
  background-color: darken(lightgray, 10%);
  cursor: pointer;
}

.invoice-object-container {
  border-radius: 5px;
}

.aq-object-card {
  // background-color: lightgray;
  border-radius: 5px;
}

.aq-object-card:hover {
  background-color: darken(lightgray, 10%);
  cursor: pointer;
}

.modal-center {
  // background-color: transparent!important;

}

// only way I can get modal width to cooperate
.modal-body {
  max-width: 1200px !important;
}

.note-box {
  background-color: lighten(@global-warning-background, 33%);
  border: 2px solid @global-warning-background;
  color: darken(@global-warning-background, 15%);
}

.note-box-success {
  background-color: lighten(@global-success-background, 5%);
  border: 2px solid darken(@global-success-background, 10%);
  color: darken(@global-success-background, 40%);
}

.close-button {
  background-color: transparent;
  color: darken(@global-warning-background, 15%);
}

.options-container {
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 1;
  top: 5px;
  right: 5px;
  left: 5px;
  bottom: 5px;
  padding: 15px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, .6);
}

.close-options:hover {
  background-color: rgba(255, 255, 255, .1) !important;
}

.aq-info {
  max-height: fit-content;
}

.aq-data {
  border-radius: 5px;
}

.options {
  margin-top: 50px;
  border-radius: 5px;
  width: fit-content;
  height: fit-content;
}

.expand {
  min-height: 100% !important;
}

.error-border {
  border: 2px solid rgb(255, 179, 179) !important;
}

@media (max-width: @breakpoint-small-max) {
  .uk-button {
    line-height: inherit;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
