//
// Button Danger
//
@button-danger-background: @global-danger-background;
//@button-danger-color: @global-color;
@button-danger-color: @global-inverse-color;
@button-danger-hover-background: darken(@global-danger-background, 8%);
@button-danger-hover-color: lighten(@global-inverse-color, 10%);
//@button-danger-active-background: darken(@button-danger-hover-background, 13%);
@button-danger-active-background: darken(@button-danger-background, 10%);
@button-danger-active-color: @global-emphasis-color;

@internal-button-danger-mode: ~'';
@internal-button-danger-hover-glow-filter: ~'';

// Ripple
.hook-button-danger() when (@internal-button-mode = ripple) {
  background-image: radial-gradient(circle 50% 100%, @button-danger-hover-background 1%, transparent 1%);
}

.hook-button-danger-hover() when (@internal-button-mode = ripple) {
  background-color: @button-danger-background;
}

.hook-button-danger-active() when (@internal-button-mode = ripple) {
  background-image: none;
}
.uk-button-danger {
  background-color: @button-danger-background;
  color: @global-danger-color;
  .hook-button-danger();
}

/* Hover */
.uk-button-danger:hover {
  background-color: @button-danger-hover-background;
  color: @button-danger-hover-color;
  .hook-button-danger-hover();
}

/* OnClick + Active */
.uk-button-danger:active,
.uk-button-danger.uk-active {
  background-color: @button-danger-active-background;
  color: @button-danger-active-color;
  .hook-button-danger-active();
}

// Glow
.hook-button-danger() when (@internal-button-danger-mode = glow) {

  &:not(.uk-hack) {
    position: relative;
    z-index: 0;
    background: none !important;
    box-shadow: none !important;
    border: none !important;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    // Fix Safari flickering
    will-change: filter;
  }

  &::before {
    background-image: @internal-button-danger-glow-gradient;
    filter: @internal-button-danger-glow-filter;
    transition: filter @button-transition-duration ease-in-out;
  }

}

.hook-button-danger-hover() when (@internal-button-danger-mode = glow) and not (@internal-button-danger-hover-glow-filter = ~'') {

  &::before { filter: @internal-button-danger-hover-glow-filter; }

}

.uk-button-danger::after:extend(.uk-button-danger) when (@internal-button-danger-mode = glow) {}
.uk-button-danger:hover::after:extend(.uk-button-danger:hover) when (@internal-button-danger-mode = glow) {}
.uk-button-danger:active::after:extend(.uk-button-danger:active) when (@internal-button-danger-mode = glow) {}