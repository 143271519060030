//
// Component: Leader
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@leader-color:                                  @global-color;


// Component
// ========================================================================

.hook-leader() { color: @leader-color; }


// Miscellaneous
// ========================================================================

.hook-leader-misc() {}


// Inverse
// ========================================================================

@inverse-leader-color:                                  @inverse-global-color;

.hook-inverse-leader() { color: @inverse-leader-color; }